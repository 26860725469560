import React from 'react'
import './styles.less';

export default class AccordionTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isOpen: false};
    this.buttonPressed = this.buttonPressed.bind(this);
  }

  buttonPressed() {
    this.setState({isOpen : !this.state.isOpen})
  }

  show() {
    if(!this.state.isOpen) return `hide`;
    else return ``;
  }

  render() {
    return (
      <div className={this.show() + " accordion-tab"}>
        <button onClick={this.buttonPressed} className="accordion-button" dangerouslySetInnerHTML={{ __html: this.props.question}}/>
        <div className='arrow'>^</div>
        <div className="accordion-text" dangerouslySetInnerHTML={{ __html: this.props.answer}}/>

      </div>
    );
  }
}
