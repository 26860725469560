const Country = {
    getCountryCode() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    getCurrency() {
        const countryCode = this.getCountryCode();

        // hasn't loaded yet
        if(countryCode.includes("UTC")) return;

        // UK based
        if(countryCode.includes("Europe/London")) return "GBP";

        // Europe based
        if(countryCode.includes("Europe")) return "EUR";

        // Outside UK and Europe
        return "USD";
    },

    getPrice() {
        const currency = this.getCurrency();
        if(currency === "GBP") return "£399 (+ VAT)";
        else if (currency === "EUR") return "€449"
        else if (currency === "USD") return "$499"
        return "Loading..."
    }
}

export default Country;