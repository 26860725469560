import React from 'react';
import './styles.less';

class Infocard extends React.Component {

  constructor(props) {
    super(props);
    // default is center aligned 
  }

  getInfoCardHero() {
    if(!this.props.img) {
        return <div className='infocard__display'>{this.props.display}</div>;
    }   
    return <img className='infocard__image' src={this.props.img} />;
  }

  render() {
    return (
    <div className={`infocard ${this.props.leftAlign ? `leftAlign`: ``}`}>
        {this.getInfoCardHero()}
        
        <div className='infocard__title'>
            {this.props.title}
        </div>
        <div className='infocard__body'>
            {this.props.body}
        </div>
    </div>
    );
  }
}

export default Infocard;
