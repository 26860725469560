import React from 'react';
import AccordionTab from "../AccordionTab";
import QuestionList from "../../data/questionList.json";

function Questions(props) {

  var items = QuestionList.map(function(item, index) {
      return <AccordionTab key={index} question={item.question} answer={item.answer}/>;
  });

  return (
    <div className="questions">
      {items}
    </div>
  )
};
export default Questions;
