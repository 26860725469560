import React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import Layout from "../components/layout";
import Block from "../components/Block";
import Infocard from "../components/Infocard";
import Component from "../components/Component";
import Questions from "../components/Questions";
import Country from "../components/Country";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Link} from "gatsby";
import Features from '../components/Features';
import Lottie from 'react-lottie-player'
import Chuck from  '../assets/chuck.jpg';
import Simon from  '../assets/simon.png';
import Templates from  '../assets/templates.jpg';
import Layers from  '../assets/layers.jpg';
import VanillaRef from  '../assets/vanilla-ref.jpg';
import Plugins from  '../assets/plugins.jpg';
import Personal from  '../assets/personal.json';
import Touches from  '../assets/touches.json';
import Experience from  '../assets/experience.json';
import Video1 from  '../assets/header-video.mp4';
import Video2 from  '../assets/realistic-interactions.gif';
import Video3 from  '../assets/brand-in-minutes.gif';
import Video4 from  '../assets/customize-confidence.gif';
import GreyBlob from '../assets/blob-grey.svg';

const Index = () => (<Layout>
  <div className="">

    <Block className="header ">
      <Component className="">
          <h1>Adapt UI Kit<br/><span className="product-subtitle">for Adobe<sup>&reg;</sup> XD<sup>&reg;</sup></span></h1>
        <p>Share functioning prototypes of Adapt courses with clients in minutes rather than days.</p>
        <AnchorLink className="btn whatisit" to="/#pricing" title="Buy it now"/>
      </Component>
    </Block>

    <Block className="heroVideo ">
      <Component className="">
         {/* <video autoPlay muted controls>
          <source src={Video1} type="video/mp4"></source>
            <a href={Video1}>Download the video.</a>
        </video> */}
        <iframe width="900" height="500" src="https://www.youtube.com/embed/VXiDisRm0vc?controls=0&autoplay=1&mute=1&playlist=VXiDisRm0vc&modestbranding=1&rel=0" title="Adapt UI Kit" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Component>
    </Block>

    <Block className="thin four-features">
      <Component>
        <div className='infocards'>
            <Infocard img={Plugins} title={'All core Adapt components'} body={"13 components, 5 extensions, 1 menu, 6 layout containers, 200+ elements. Created in 3 sizes: mobile phone, single-width, full-width. More components to come."}/>
            <Infocard img={Layers} title={'Well-layered and organized'} body={"Atomic construction means components layers mirror Adapt code. Page layouts are built with articles, blocks, and components."}/>
            <Infocard img={Templates} title={'Templates for mobile & desktop'} body={"Course prototypes are supplied in two sizes: mobile phone and desktop. Customize these directly by drag-n-dropping both components and images."} />
            <Infocard img={VanillaRef} title={'Vanilla theme resources'} body={"References for colors, typography, and 238 icons. Use these to build your palette and font hierarchy. Update them to start your own style guide."}/>
        </div>
      </Component>
    </Block>


    <Block className="describe ">
      <Component className='lightPurpleGradient '>
        <p className=""><span>The Adapt UI Kit</span> is a collection of Adobe<sup>&reg;</sup> XD<sup>&reg;</sup> components, styles, and resources based on the Adapt e-learning framework.</p>
        <ul>
            <li>Drag and drop Adapt components to build high quality prototypes of Adapt courses</li><li>Apply styles that cascade throughout components</li><li>Create storyboards</li><li>Share code with your developers</li><li>Prototype custom components</li>
        </ul>
        <p className="">The collection is too long to list, so check out the <a href='https://drive.google.com/uc?export=download&id=1N6Ux850-gMvAr5VnO5GoNwsbGED1MpOM'>preview file</a> instead!</p>
      </Component>
    </Block>

    <Block className="steps thin ">
      <Component>
        <div className='infocards'>
            <Infocard display={"1"} leftAlign={true} title={"Copy templates"} body={"Begin by duplicating a template. Then duplicate enough blocks to accommodate your components. Duplication preserves the padding that imitates the Adapt's Vanilla theme."} />
            <Infocard display={"2"} leftAlign={true} title={'Drag components'} body={"XD components &mdash; modeled with precision on Adapt's standard components &mdash; are ready for you to drag into place. It couldn't be simpler!"}/>
            <Infocard display={"3"} leftAlign={true} title={'Customize styles'} body={"Introduce branding by customizing color and typography styles. Your styles cascade through-out the components. Have fun with borders and spacing, too."}/>
            <Infocard display={"4"} leftAlign={true} title={'Add content'} body={"Delete placeholder you don't need. Copy and paste text into position. Drag images into place as graphics and backgrounds."}/>
        </div>
      </Component>
    </Block>

    <Block className="thin">
      <Component>
          <AnchorLink className="btn purchase" to="/#pricing" title="Tell me about pricing"/>
      </Component>
    </Block>

    <Block className="cyanGradient">
      <Component>
        <h2 id="">Functional and interactive</h2>
          <p className="">The UI Kit components have been constructed using XD’s latest features: stacks, padding, and states. They have been wired as functional prototypes where possible and appropriate. Buttons change color on hover and switch pages when they’re clicked.</p><p>Wireframes and static mock-ups fill a need. But there are times that call for a little extra. Now you can have that little extra every time. Prototypes are no longer a luxury with the Adapt UI Kit. Let them find a regular place in your solution development, brainstorming, and art direction.</p>
      </Component>
      <Component className="">
        <img src={Video2} />
      </Component>
    </Block>

    <Block className="cyanGradient left">
    <Component className="">
      <img src={Video3} />
      </Component>
      <Component>
        <h2 id="">Branded in minutes</h2>
          <p className="">The Adapt UI Kit uses the same cascade as the Adapt framework. Simply change a color or font in the Assets Panel and watch it propagate throughout your design. Basic branding is that easy. Take it up a notch with backgrounds: assign colors in the property inspector and drop in place your images. See how rapidly the visual brand takes shape. We’ve spent time faithfully recreating Adapt components so that you can spend time on the creative process. Create something that will impress!</p>
      </Component>
    </Block>

    <Block className="cyanGradient">
      <Component>
        <h2 id="">Customized with Confidence</h2>
          <p className="">When you create a design with the Adapt UI Kit you begin with accuracy. We worked diligently to reproduce faithfully the dimensions and spacing of Adapt components. Starting your design with an accurate model supplies a type of guard rail. Modify whatever you want. But whatever is not changed remains true to Adapt styles. This produces a result that is easier to implement, and one that is less open to interpretation.</p><p>When your design is ready for production, your developer will appreciate the coordination between XD components and Adapt code. Naming conventions are familiar. Nested elements are predictable.</p>
      </Component>
      <Component className="">
        <img src={Video4} />
      </Component>
    </Block>

    <Block className="purple thin quote">
      <Component>
        <div className="quote">
          <p className="blockquote">"What really separates Adapt from other authoring tools like Storyline is the full page, vertical scrolling experience. Creating Adapt courses is more akin to professional web design. The Adapt UI Kit makes it able to create these experiences in minutes."</p><p><strong>- Simon Date, Adapt Tips co-founder</strong></p>
        </div>
        <img src={Simon} />
      </Component>
    </Block>

    <div className='article hasBackground'>
        <div className='article__inner'  style={{backgroundImage: `url(${GreyBlob})`}}>
        <Block className="delight-clients">
        <Component>
        <Lottie className='firstLottie' loop play animationData={Personal}
            style={{margin: "0 auto" }}
            />
            <h2>Delight clients</h2>
            <p>If your business involves working with clients, you'll know how important their brand identity is to them. Using the UI kit you can create a prototype to show them an example of what their branded content will look like in Adapt.</p>
            <p>This extra prototyping step is used by some of the top e-learning agencies as a way to bring extra confidence and prove themselves as a premium supplier.</p>
        </Component>
        </Block>

        <Block className="">
        <Component className="">
            <h2>Keep the team aligned</h2>
            <p>When working with a team of designers and developers it's critical to ensure they are on the same page. Many designers may only have limited experience with Adapt and may not be aware of how each interaction works.</p>
            <p>By allowing them to build interactive prototypes themselves with all the components and extensions avilable to them they can become more acustom to Adapt.</p>
            <Lottie loop play animationData={Touches}
            style={{ width: 350, height: 350, margin: "0 auto" }}
            />
        </Component>
        <Component>
        <h2>Save designer's time</h2>
            <p>We've spent hundreds of hours making this UI kit the perfect tool for any team using Adapt. Designers can use the template and start creating prototypes from the start.</p>
            <Lottie loop play animationData={Experience}
            style={{ width: 350, height: 350, margin: "0 auto" }}
            />
        </Component>
        </Block>
        </div>
    </div>
    

    <Block className="purple thin quote">
      <Component>
          <div className="quote">
            <p className="blockquote">"We'd been discussing the hurdles teams encounter when working with Adapt. We wanted to create something that would lower them, someting that would improve the workflow. And for over a year the UI Kit was at the center of that conversation. Now &mdash; finally &mdash; teams have a tool that can save them dozens of hours per project."</p><p><strong>- Chuck Lorenz, Adapt Tips co-founder</strong></p>
          </div>
          <img src={Chuck} />
      </Component>
    </Block>

    <Block className="swap featureList" >
        <Component>
            <h2 id="pricing">Adapt UI Kit</h2>
            <div className="money">{Country.getPrice()}</div>
            <OutboundLink className="purchase-button" href='https://buy.stripe.com/4gwcMU51V0CZg5acMM'>BUY NOW</OutboundLink>
            <p>You'll receive instructions on how to download the UI Kit upon purchase.</p><p>By purchasing you are agreeing to the <Link to="/licence">licence</Link>.</p>
        </Component>
        <Component>
            <h2 className="featureTable">What's included?</h2>
            <Features />
        </Component>
    </Block>

    <Block>
      <Component className="faq">
        <h2>Frequently Asked Questions</h2>
          <Questions/>
      </Component>
    </Block>

  </div>
</Layout>)
export default Index;
