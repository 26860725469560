import React from 'react'
import './features.less';

export default class Features extends React.Component {

  constructor(props) {
    super(props);
  }


  render(props) {
    return (
      <div className="pricing-table">
        <div className="pricing-table-container">
          <table>
          <tbody>
              <tr>
                <td>✓</td>
                <td>UI Kit for XD</td>
                <td>We'll email you a link to download the Adapt UI Kit upon purchase.</td>
              </tr>
              <tr>
                <td>✓</td>
                <td className="">Ongoing updates</td>
                <td>In addition to the standard bug fix guarantee you'd expect, you'll get free access to new Adapt components as they are added to the UI Kit.</td>
              </tr>
              {/* 
              <tr className="comingSoon">
                <td>✓</td>
                <td className="">UI Kit for Figma</td>
                <td>Due out in 2023. We will create a version for teams using Figma. Purchase the UI Kit now will guarntee.</td>
              </tr>
              */}
              <tr>
                <td>✓</td>
                <td className="">Video guides</td>
                <td>Full access to a series of short videos that explain how to use the UI Kit. Accessed through the <a href='https://adapt.tips'>ADAPT.tips website</a>.</td>
              </tr>
              <tr>
                 <td>✓</td>
                <td className="">Personal Support</td>
                <td>We want your team to have the highest level of success with our product and will support them via phone or email with any questions or queries they have.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
